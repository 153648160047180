<template>
  <ClFormRow :label="label" :toolTipText="tooltip" name="walletTypeId" ref="walletTypeRef">
    <template #content>
      <multiselect
        @search-change="onInput"
        v-model="selectedWalletType"
        :options="walletTypeOptions"
        :hide-selected="true"
        :selectLabel="selectLabel"
        :block-keys="['Tab', 'Enter']"
        required
        label="name"
        track-by="id"
        class="custom-multi-select zq--multiselect"
        :searchable="searchable"
        placeholder="Type here..."
      >
        <span slot="noOptions"></span>
      </multiselect>
      <img
        src="../../assets/icons/search.svg"
        width="16"
        height="16"
        alt="search"
        class="zq--multiselect-search-icon"
      />
    </template>
  </ClFormRow>
</template>

<script>
import { formConfig } from '@/config';
import { mapActions } from 'vuex';

export default {
  name: 'ZqSelectWalletTypeId',
  props: {
    value: [Array, String],
    label: String,
    tooltip: String,
    css: [String, Object],
    multiple: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      selectedWalletType: null,
      selectLabel: formConfig.selectLabel,
      walletTypeOptions: [],
      initOptions: null,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    selectedWalletType: {
      deep: true,
      handler(val) {
        this.$emit('input', this.selectedWalletType.id)
      }
    }
  },
  methods: {
    ...mapActions('walletTypes', ['handleGetWalletTypes']),
    async initialize() {
      this.walletTypeOptions = [];
      let walletTypesData;
      if (this.value) {
        const payload = { idArray: [this.value], limit: 20, skip: 0 };
        const walletTypesData = await this.handleGetWalletTypes(payload);

        const selectedWalletTypeData = walletTypesData.map(walletType => ({
          id: walletType.id,
          name: `${walletType.id} - ${walletType.currencyCode} (${walletType.currencySymbol})`, // id - USD ($)
        }));

        this.selectedWalletType = selectedWalletTypeData[0]
      }

      const payload = { idArray: [], limit: 20, skip: 0 };
      walletTypesData = await this.handleGetWalletTypes(payload)

      this.walletTypeOptions = walletTypesData.map(walletType => ({
        id: walletType.id,
        name: `${walletType.id} - ${walletType.currencyCode} (${walletType.currencySymbol})`, // id - USD ($)
      }));
    },
    async onInput(val) {
      if (val.length >= 1) {
    //     this.walletTypeOptions = await this.handleGetWalletTypesByQuery({
    //       queryRequest: {
    //         multiFields: [
    //           {
    //             queryFields: ['name'],
    //             queryValue: val
    //           }
    //         ],
    //         sortBy: [
    //           {
    //             queryField: 'name',
    //             order: 'Asc',
    //           },
    //         ],
    //       }
    //     })
      }
      if (val.length === 0) {
    //     this.walletTypeOptions = this.initOptions ?? [];
      }
    }
  }
};
</script>

<style scoped lang="scss">

</style>
